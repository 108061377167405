import { Box, Button, Card, Container, Flex } from '@energiebespaarders/symbols';
import { mediaMin } from '@energiebespaarders/symbols/styles/breakpoints';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useActiveHouseId } from '~/hooks/useActiveHouseId';
import { useStartFunnel } from '~/hooks/useFunnel';
import { DutyPackageName } from '~/types/graphql-global-types';
import type { PartnerKey } from '~/utils/constants';
import { DEB_PARTNERS } from '~/utils/debPartners';
import AddressInitForm from '../AddressInitForm';
import { getBgGradient } from './utils';
import type { DefaultTheme } from 'styled-components';

interface StyledHeroProps {
  bgImageUrl?: string;
  partner?: PartnerKey;
  theme: DefaultTheme;
}

export const StyledHero = styled.section<StyledHeroProps>`
  position: relative;
  align-content: center;
  color: white;
  background-repeat: no-repeat;
  background-size: 100%, 100%, 100%, cover;
  background-position: center;
  ${x => getBgGradient('xs', x.bgImageUrl, x.partner)};

  ${mediaMin.sm} {
    ${(x: StyledHeroProps) => getBgGradient('sm', x.bgImageUrl, x.partner)};
  }

  ${mediaMin.md} {
    ${(x: StyledHeroProps) => getBgGradient('md', x.bgImageUrl, x.partner)};
  }

  ${mediaMin.lg} {
    ${(x: StyledHeroProps) => getBgGradient('lg', x.bgImageUrl, x.partner)};
  }

  ${mediaMin.xl} {
    ${(x: StyledHeroProps) => getBgGradient('xl', x.bgImageUrl, x.partner)};
  }

  & h1 {
    font-size: ${x => x.theme.type.scale[2]};
  }
`;

const StyledHeroSubTitle = styled.div`
  display: inline-block;
  font-weight: 500;
  color: white;
`;

interface HeroProps {
  useEbvCheck?: boolean;
  partner?: PartnerKey;
  title?: string;
  bgImageUrl?: string;
}

const Hero: React.FC<HeroProps> = ({ partner, title, bgImageUrl }) => {
  const [addressFromParams, setAddressFromParams] = useState<{
    zip: string;
    number: number;
    suffix?: string;
  }>({ zip: '', number: 0 });

  const { activeHouseId } = useActiveHouseId();
  const [selectedHouseId, setSelectedHouseId] = useState(activeHouseId);
  const [editingAddress, setEditingAddress] = useState(false);

  const router = useRouter();
  const params = router.query;

  useEffect(() => {
    if (params?.postcode && params?.huisnummer && !addressFromParams) {
      const { postcode, huisnummer, toevoeging } = params as {
        postcode: string;
        huisnummer: string;
        toevoeging: string;
      };
      setAddressFromParams({
        zip: postcode.toUpperCase(),
        number: parseInt(huisnummer, 10),
        suffix: toevoeging,
      });
    }
  }, [addressFromParams, params]);

  const partnerPackage = DEB_PARTNERS.find(p => p.key === partner)?.dutyPackageName;
  const { startFunnel, loading } = useStartFunnel({
    dutyPackage: partnerPackage || DutyPackageName.quotesLead,
  });

  return (
    <StyledHero partner={partner} bgImageUrl={bgImageUrl}>
      <Container color="white" py={5}>
        <Flex flexWrap="wrap" mx={[0, 0, -5]} px={4} py={10} alignItems="center">
          <Box width={[1, 1, 1 / 2]} px={[2, 2, 5]}>
            <h1 style={{ lineHeight: 1.2, fontSize: '2.5em' }}>
              {title || (
                <>
                  Duurzaam wonen? <br /> Zo geregeld!
                </>
              )}
            </h1>

            <StyledHeroSubTitle>
              Vind geschikte producten voor jouw woning en vraag gemakkelijk offertes aan van
              betrouwbare installateurs
            </StyledHeroSubTitle>
          </Box>
          <Box width={[1, 1, 1 / 2]} px={[2, 2, 5]}>
            <Card bgColor="rgba(255, 255, 255, 0.35)">
              <AddressInitForm
                isActive
                initialValues={addressFromParams}
                selectedHouseId={selectedHouseId}
                setSelectedHouseId={setSelectedHouseId}
                buttonLabel="Start Slimme Woningscan →"
                buttonIcon={null}
                buttonColor="grayBlack"
                buttonBgColor="yellow"
                linkColor="grayDarker"
                onHouseInitialized={startFunnel}
                setEditingCallback={v => setEditingAddress(v)}
              />
              {selectedHouseId && !editingAddress && (
                <Button
                  onClick={() => startFunnel(selectedHouseId)}
                  bgColor="yellow"
                  color="grayBlack"
                  label="Start Slimme Woningscan →"
                  fluid
                  loading={loading}
                  fontSize={6}
                />
              )}
            </Card>
          </Box>
        </Flex>
      </Container>
    </StyledHero>
  );
};

export default Hero;
