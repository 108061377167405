import { Box, Card, Container, Flex, Image } from '@energiebespaarders/symbols';
import { Center, Color, Medium, Smaller } from '@energiebespaarders/symbols/helpers';
import React from 'react';
import styled from 'styled-components';
import { padding, themify } from '~/styles/mixins';
import newTheme from '~/styles/theme';
import { testimonials as defaultTestimonials } from './testimonials';
import type { TestimonialSlide } from './testimonials';

const StyledSection = styled.section<{ $bgColor: string }>`
  ${padding(10, 'y')};
  background: ${x => x.$bgColor};
`;

const StyledCard = styled(Card)`
  font-family: ${newTheme.type.fontFamilySerif};
  font-weight: 500;
  font-style: italic;
  text-align: center;
  min-height: 108px;
  display: flex;
  align-items: center;
`;

const TestimonialSlider: React.FC<{ testimonials?: TestimonialSlide[]; bgColor?: string }> = ({
  testimonials = defaultTestimonials,
  bgColor = 'transparent',
}) => {
  return (
    <StyledSection $bgColor={themify(bgColor)}>
      <Container>
        <Center block>
          <h2>Dit zeggen onze klanten</h2>
        </Center>

        <Flex flexWrap="wrap" mx={[0, 0, -2]} px={[0, 0, 2]}>
          {testimonials.map(testimonial => (
            <Box key={`${testimonial.name}`} width={[1, 1, 1 / 3]} px={2}>
              <StyledCard fontSize={6} shadow="sm" p={2} mb={2}>
                “{testimonial.quote}”
              </StyledCard>
              <Flex mb={2}>
                <Box width={0.25} textAlign="right">
                  <Image src={testimonial.photo} alt="" width="60px" radius="100px" />
                </Box>
                <Box width={1} px={2} style={{ lineHeight: 1 }}>
                  <Smaller>
                    <Color c="grayDarker">
                      <div>
                        <Medium>{testimonial.name}</Medium>
                      </div>
                      {testimonial.subtitle}
                    </Color>
                  </Smaller>
                </Box>
              </Flex>
            </Box>
          ))}
        </Flex>
      </Container>
    </StyledSection>
  );
};

export default TestimonialSlider;
