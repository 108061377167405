import { customerTestimonialImages } from '~/utils/constants';

export interface TestimonialSlide {
  name: string;
  quote: string;
  subtitle: string;
  photo: string;
}

export const testimonials: TestimonialSlide[] = [
  {
    name: 'Thomas van Veldhoven',
    quote:
      'Ik vind het erg leuk dat ik op die manier mijn ouders heb geïnspireerd om ook duurzamer te gaan leven!',
    subtitle: 'Bespaart energie met vloerisolatie en zonnepanelen',
    photo: customerTestimonialImages.vanveldhovenProfile,
  },
  {
    name: 'Familie van den Broek',
    quote: 'Het is een groep leuke jonge mensen en ze hebben een enorme drive.',
    subtitle: 'Wekt energie op met zonnepanelen',
    photo: customerTestimonialImages.vandenbroekProfile,
  },
  {
    name: 'Familie Flohil',
    quote: 'Ik hoefde niks te doen. Alleen een kopje koffie zetten, ze gingen meteen aan de slag.',
    subtitle: 'Bespaart energie met spouwmuurisolatie',
    photo: customerTestimonialImages.flohilProfile,
  },
];
